import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter, Router,
    RouterProvider,
} from "react-router-dom"
import Statistics from "./statistics";
import Suggestions from "./suggestions";

const routes = createBrowserRouter([
    {
        path: '/',
        element: <App />,
    },
    {
        path: '/statistics',
        element: <Statistics />,
    },
    {
        path: '/suggestions',
        element: <Suggestions />,
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={routes} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
