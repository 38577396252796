import GlobalLayout from "./globalLayout";

function Statistics() {
    return (
        <GlobalLayout>
            <div>stastics</div>
            <div>施工中</div>
        </GlobalLayout>
    )
}

export default Statistics;